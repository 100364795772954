import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum FeedbackBubbleVisibility {
  VISIBLE,
  INVISIBLE,
}

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private feedbackBubbleVisibilityState = new BehaviorSubject<FeedbackBubbleVisibility>(/* initialValue= */ FeedbackBubbleVisibility.VISIBLE);

  getFeedbackBubbleVisibilityState() {
    return this.feedbackBubbleVisibilityState;
  }

  setFeedbackBubbleVisibilityState(state: FeedbackBubbleVisibility) {
    this.feedbackBubbleVisibilityState.next(state);
  }
}
